﻿@import "~@ewstheme/core/lib/scss/variables";

.ews-advance-panel-button-container {
  border-bottom: 1px solid $gray3;
  padding-top: .5rem;
  text-align: right;
}

.ews-advance-panel-items {
  margin-bottom: 0.5rem;
  
  &.panel-open {
    padding: 0.5rem;
    border-bottom-left-radius: $pt-border-radius;
    border-bottom-right-radius: $pt-border-radius;
    border-left: 1px solid $gray3;
    border-bottom: 1px solid $gray3;
    border-right: 1px solid $gray3;
    box-shadow: $pt-elevation-shadow-2;
  }
}