@import "~@ewstheme/colors/lib/scss/colors";

.ews-dialog {
  &.ews-dialog--primary {
    .bp4-dialog-header {
      background-color: $purple3;
      color: $white;

      .bp4-heading, .bp4-icon {
        color: $white;
      }
    }
  }

  &.ews-dialog--success {
    .bp4-dialog-header {
      background-color: $green3;
      color: $white;

      .bp4-heading, .bp4-icon {
        color: $white;
      }
    }
  }

  &.ews-dialog--warning {
    .bp4-dialog-header {
      background-color: $orange3;
      color: $white;

      .bp4-heading, .bp4-icon {
        color: $white;
      }
    }
  }

  &.ews-dialog--danger {
    .bp4-dialog-header {
      background-color: $red3;
      color: $white;

      .bp4-heading, .bp4-icon {
        color: $white;
      }
    }
  }
}