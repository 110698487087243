﻿.ews-bg-image-container {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  
  .ews-bg-image {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    opacity: 1;
  }
}