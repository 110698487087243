@import "~@ewstheme/colors/lib/scss/colors";
@import "~@ewstheme/core/lib/scss/variables";

.ews-inline-notification--wrapper {
  margin: auto;
  display: flex;
  justify-content: center;

  .ews-inline-notification {
    border-radius: $pt-border-radius;
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    padding: 1rem 0 1rem 0;
    background-color: $light-gray3;
    color: $gray1;
    border: 1px solid $gray5;

    &.fill {
      width: 100%;
    }

    button {
      position: absolute;
      right: .3rem;
      top: .3rem;
      float: right;
      font-weight: 700;
      outline: none;
      border: none;
      opacity: .8;
      line-height: 1;
      font-size: 16px;
      padding: 0;
      cursor: pointer;
      background: 0 0;
      border: 0;
      color: $gray1;
    }

    .ews-inline-notification--icon {
      flex: 0 0 auto;
      width: auto;
      align-self: center;
      padding: 0 .5rem 0 1rem;
    }

    .ews-inline-notification--text {
      flex: 1 0 0%;
      width: 100%;
      padding: 0 1.5rem 0 .5rem;

      h3 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }

    &.primary {
      background-color: lighten($purple5, 10%);
      color: $purple1;
      border: 1px solid $purple3;

      button {
        color: $purple1;
      }

      .ews-inline-notification--text {
        h3 {
          border-bottom: 1px solid $purple3;
        }
      }
    }

    &.success {
      background-color: lighten($green5, 20%);
      color: $green1;
      border: 1px solid $green3;

      button {
        color: $green1;
      }

      .ews-inline-notification--text {
        h3 {
          border-bottom: 1px solid $green3;
        }
      }
    }

    &.warning {
      background-color: lighten($orange5, 20%);
      color: $orange1;
      border: 1px solid $orange3;

      button {
        color: $orange1;
      }

      .ews-inline-notification--text {
        h3 {
          border-bottom: 1px solid $orange3;
        }
      }
    }

    &.danger {
      background-color: lighten($red5, 15%);
      color: $red1;
      border: 1px solid $red3;

      button {
        color: $red1;
      }

      .ews-inline-notification--text {
        h3 {
          border-bottom: 1px solid $red3;
        }
      }
    }
  }
}