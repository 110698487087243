@import "~@ewstheme/core/lib/scss/variables";

.ews-form--error-container{
  border: 1px solid $pt-intent-danger;
  border-radius: 3px;
  width: 100%;
  text-align: center;
  padding: 0.25rem;
  color: $pt-intent-danger;
  background-color: lighten($pt-intent-danger, 35%);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ews-input-field--container {
  position: relative;
}

.ews-input-field--helper-dialog {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.ews-input-field {
  .bp4-input-action {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 0.25rem;
  }
}

.ews-textarea-field--container {
  position: relative;
}

.ews-textarea-field--helper-dialog {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.ews-textarea-field {
  resize: none;
}

.ews-checkbox--group-container {
  position: relative;
}

.ews-checkbox--group-helper-dialog {
  position: absolute;
  top: 7px;
  right: 0;
  z-index: 1;
}

.ews-checkbox--group-items {
  &.ews-checkbox--group-inline:not(.ews-checkbox--group-scrollable) {
    display: flex;
    flex-wrap: wrap;
  }

  &.ews-checkbox--group-scrollable {
    max-height: 200px;
    overflow-y: auto;
    border: $gray5 1px solid;
    border-radius: .25rem;
    padding-left: .5rem;
    padding-right: .5rem;

    &.error {
      border-color: $red3;
    }
  }
}

.ews-datepicker--container {
  position: relative;

  .bp4-input-action .bp4-icon-error.bp4-intent-danger {
    padding-top: 7px;
    padding-right: 7px;
  }
}
.ews-datepicker--helper-dialog {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.ews-number-input-field--container {
  position: relative;
}
.ews-number-input-field--helper-dialog {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.ews-number-input-field {
  .bp4-input-action {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 0.25rem;
  }
}

.ews-password-field--strength {
  border-radius: 0 0 5px 5px;

  .bp4-progress-meter {
    border-radius: 0 0 5px 5px;
  }
}

.ews-radio--container {
  position: relative;
}

.ews-radio--helper-dialog {
  position: absolute;
  top: 7px;
  right: 0;
  z-index: 1;
}

.ews-radio--items {
  &.ews-radio--inline:not(.ews-radio--scrollable) {
    display: flex;
    flex-wrap: wrap;
  }

  &.ews-radio--scrollable {
    max-height: 200px;
    overflow-y: auto;
    border: $gray5 1px solid;
    border-radius: .25rem;
    padding-left: .5rem;
    padding-right: .5rem;

    &.error {
      border-color: $red3;
    }
  }
}

.ews-select-field--container {
  position: relative;
}
.ews-select-field--helper-dialog {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.ews-slider--container {
  position: relative;

  .ews-slider--helper-dialog {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .bp4-form-content {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .bp4-slider {
    .bp4-slider-handle {
      .bp4-slider-label {
        width: max-content;
        max-width: 100px;
        text-align: center;
      }
    }

    &.bp4-vertical {
      width: 100%;

      .bp4-slider-handle {
        .bp4-slider-label {
          text-align: left;
        }
      }
    }

  }
}

.ews-switch--container {
  position: relative;
}
.ews-switch--helper-dialog {
  position: absolute;
  top: 7px;
  right: 0;
  z-index: 1;
}

.ews-checkbox-group--container {
  position: relative;
}

.ews-checkbox-group--helper-dialog {
  position: absolute;
  top: 7px;
  right: 0;
  z-index: 1;
}

.ews-checkbox-group--items {
  &.ews-checkbox-group--inline:not(.ews-checkbox-group--scrollable) {
    display: flex;
    flex-wrap: wrap;
  }

  &.ews-checkbox-group--scrollable {
    max-height: 200px;
    overflow-y: auto;
    border: $gray5 1px solid;
    border-radius: .25rem;
    padding-left: .5rem;
    padding-right: .5rem;

    &.error {
      border-color: $red3;
    }
  }
}

