.ews-table-responsive {
  overflow-x: auto;
}

.ews-table-container:not(.ews-table-responsive){
  .ews-table{
  }
}


.ews-table {
  min-width: 100%;
  border-width: 1px;
  border-color: $light-gray3;
  border-style: solid;
  border-collapse: collapse;
  table-layout: fixed;
  background-color: white;

  .bp4-radio {
    margin-bottom: 0;
  }

  tr:not(:last-child) {
    border-bottom-width: 1px;
    border-bottom-color: $light-gray3;
    border-bottom-style: solid;
  }

  > thead > tr:last-child {
    border-bottom-width: 1px;
    border-bottom-color: $light-gray3;
    border-bottom-style: solid;
  }

  > thead > tr {
    background-color: $purple3;
    color: $white;
  }

  > tbody {
    > tr {
      &:nth-child(even) {
        background-color: $light-gray3;

      }

      &.ews-table-primary{
        background-color: $purple5;
      }

      &.ews-table-success{
        background-color: $green5;
      }

      &.ews-table-warning{
        background-color: $orange5;
      }

      &.ews-table-danger{
        background-color: $red5;
      }

      &.ews-table-light{
        background-color: $gray5;
      }

      &.ews-table-dark{
        background-color: $dark-gray5;
        color: $white;
      }

      &:hover {
        background-color: $purple5;
      }
    }
  }

  > tfoot {
    tr {
      background-color: $gray4;
      color:white;
    }
  }

  td, th {
    padding: 0.25rem;
    text-align: left;



    &.ews-table-primary{
      background-color: $purple5;
    }

    &.ews-table-success{
      background-color: $green5;
    }

    &.ews-table-warning{
      background-color: $orange5;
    }

    &.ews-table-danger{
      background-color: $red5;
    }

    &.ews-table-light{
      background-color: $gray5;
    }

    &.ews-table-dark{
      background-color: $dark-gray5;
      color: $white;
    }
  }
}