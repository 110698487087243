@import "~@ewstheme/colors/lib/scss/colors";
@import "~@ewstheme/core/lib/scss/variables";

.DirectoryExplorer {
  display: flex;
  height: calc(100% + 2rem);
  flex-direction: column;
  gap: .5rem;
  margin: -1rem;
  
  .InlineControlsContainer {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    border-bottom: 1px solid $gray3;
    gap: .25rem;
  }
  
  .DirectoryNavigation {
    display: flex;
    flex-direction: row;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .DirectoryBreadCrumb {
      flex-grow: 1;
    }
  }
  
  .DirectoryViewer {
    height: -webkit-fill-available;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    
    .ViewContainer {
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      
      .EmptyFolderLabel {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
        
        span {
          display: block;
          padding-top: 2rem;
          font-size: 1.5rem;
          font-weight: 400;
          color: $pt-intent-primary;
        }
      }
      
      &.WithSpacing {
        gap: .5rem;
      }
      
      .ViewItem {
        border-radius: $pt-border-radius;
        &.GridView {
          width: 150px;
          cursor: pointer;
          
          .FolderGridContainer {
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            
            .FolderIcon {
              align-items: center;
              padding-top: 1rem;
              padding-bottom: 1rem;
              height: calc(60px + 2rem);
              
              span {
                width: 100%;
                font-size: 60px;
              }
            }
            
            .FolderName {
              text-align: center;
              width: 150px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              padding: .25rem;
            }
            
            .Selector {
              position: absolute;
              top: .25rem;
              left: .25rem;
              z-index: 10;
            }
          }

          .FileGridContainer {
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;

            .FileIcon {
              align-items: center;
              padding-top: 1rem;
              padding-bottom: 1rem;
              height: calc(60px + 2rem);

              span {
                width: 100%;
                font-size: 60px;
              }
            }
            
            .FileImage {
              height: calc(60px + 2rem);
            }

            .FileName {
              text-align: center;
              width: 150px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              padding: .25rem;
            }

            .Selector {
              position: absolute;
              top: .25rem;
              left: .25rem;
              z-index: 10;
            }
          }
        }
        
        &.List {
          width: 100%;
          cursor: pointer;
        }
        
        &:hover, &.FolderSelected {
          background-color: lighten($purple5, 10%);
          
          .FileImage {
            opacity: 0.65;
          }
        }
      }
    }

    .ViewHeader {
      width: 100%;
      font-weight: 600;
      font-size: 110%;
      border-bottom: 1px solid $pt-intent-primary;
      display: flex;
      flex-direction: row;
      padding-left: .5rem;
      padding-right: .5rem;
    }

    .viewRow {
      width: 100%;
      border-bottom: 1px solid $gray4;
      display: flex;
      flex-direction: row;
      padding-top:.5rem;
      padding-left: .5rem;
      padding-right: .5rem;
    }

    .SelectorCell {
      width: 36px;
    }

    .IconCell {
      width: 1.2rem;
    }

    .TitleCell {
      flex-grow: 1;
    }

    .MediaTypeCell {
      width: 150px;
    }

    .FileSizeCell {
      width: 75px;

    }

    .UploadDateCell {
      width: 110px;
    }
  }
}

.DirectoryTreeView {
  height: 400px;
  width: 100%;
  overflow: auto;
  border: 1px solid $gray3;
  border-radius: $pt-border-radius;
  
  .DirectoryTreeContainer {
    width: fit-content;
    min-width: 100%;
    height: fit-content;
    
    .DirectoryTreeIcon {
      margin-right: .25rem;
    }

    .Hidden {
      opacity: .5;
    }
    
    .Muted {
      color:$gray3;
    }
    
    .MutedBG {
      background-color: $light-gray4;
    }
  }
}

.RecyclingBinIcon {
  width: 1em;
  height: 1em;
  line-height: 1em;
  display: inline-block;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("~/public/img/recycle-bin.png");
}

.FileUploadDetails {
  display: flex;
  flex-direction: row;
  gap: .24rem;
  align-items: center;
  border-bottom: 1px solid $light-gray2;
  
  .FileUploadName{
    flex-grow: 1;
  }
  
  .FileUploadProgress{
    width: 200px;
  }
  
  .UploadError{
    color: $pt-intent-danger;
  }
}

.MediaViewPreview {
  position: relative;
  padding-top: 66.66666%;
  width: 100%;
  border: 1px solid $gray1;
  border-radius: $pt-border-radius;
  background-color: $gray3;
  
  &.Embedded {
    background-color: black;
  }

  .MediaViewPreviewWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    iframe {
      width: 100%;
      border: none;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .MediaViewPreviewIcon {
      width: 200px;
      height: 200px;
    }

    .ImageDownload {
      position:absolute;
      bottom: 1rem;
      right: 1rem;
    }
  }
}

.MediaImageUrlGenerator {
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 0.5rem;
  
  .MediaImageUrlGeneratorControls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
  }
  
  .MediaImageUrlResult {
    flex-grow: 1;
  }
}

.DeletedMediaPreview {
  height: 100%;
  width: 100%;
  position: relative;
  border: 1px solid $gray1;
  border-radius: $pt-border-radius;
  background-color: $gray3;

  &.Embedded {
    background-color: black;
  }
  
  .DeletedMediaPreviewWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    iframe {
      width: 100%;
      border: none;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .MediaViewPreviewIcon {
      width: 100px;
      height: 100px;
    }

    .ImageDownload {
      position:absolute;
      bottom: 1rem;
      right: 1rem;
    }
  }
}

.MediaSearchContainer {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  .MediaSearchText {
    flex-grow: 1;
  }
}

.MediaSearchResultsContainer {
  width: 100%;
  background: white;
  border: 1px solid $gray5;
  height: 400px;
  overflow-y: auto;
  padding: 0.25rem;
  margin-top: 0.5rem;
}