﻿.ews-workarea--container {
  height: calc(100% + 2rem);
  margin: -1rem;
  display: flex;
  flex-direction: column;
  
  .ews-workarea--header {
    padding: 1rem;
    border-bottom: 1px solid $gray3;
  }

  .ews-workarea--wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 1;
    overflow-y: auto;

    .ews-workarea--main {
      flex-grow: 1;
      padding: 1rem;
    }

    .ews-workarea--actions {
      border-left: 1px solid $gray3;
      display:flex;
      flex-direction: row;

      .ews-workarea--actions-toggle {
        background-color: $gray3;
        color: $white;
        align-items: center;
        display:flex;
        cursor: pointer;
      }

      .ews-workarea--actions-content {
        padding: 1rem;
        width: 250px;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        
        &.collapsed {
          width: 0;
          padding-left: 0;
          padding-right: 0;
        }
        
        button {
          white-space: nowrap;
        }
        
        .action-divider {
          margin-top: 0.25rem;
          margin-bottom: 0.25rem;
          border-bottom: 1px solid $gray3;
        }
      }
      
    }
  }
}
