@import "~@ewstheme/colors/lib/scss/colors";
@import "~@ewstheme/core/lib/scss/variables";

.ews-file-categories-container {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;

  .ews-file-categories-chart {
    width: 50px;
    flex: 0 0 50px;
    height: 100%;

    .ews-file-category-chart-wrapper {
      display: flex;
      flex-direction: column-reverse;
      align-items: end;
      height: 100%;
      background-color: $gray4;
      border-radius: $pt-border-radius;

      .chart-part {
        width: 100%;
      }
    }
  }

  .ews-file-category-chart-labels {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;

    .label-part {
      flex: 1 0 0%;
      font-size: .75rem;
      color: $gray4;
      padding-left: .25rem;
      width: 60px;
      display:flex;
      flex-direction: column;

      span {
        display: block;
        width: 100%;
        &:last-child:not(:first-child) {
          margin-top:auto;
        }
      }

      &:not(:last-child){
        span:first-child {
          margin-top: -.375rem;
        }
      }

    }
  }

  .ews-file-category-chart-summary {
    display: flex;
    flex: 1 0 0%;
    justify-content: center;
    align-items: center;

    .summary-wrapper {
      margin: auto;
      display: flex;
      flex-direction: column-reverse;

      > div {
        margin-bottom: .25rem;
      }

      .summary-point {
        padding-top: 14px;
        padding-left: 14px;
        border-radius: 50%;
        margin-right: .5rem;
        line-height: 1;
        display: inline-block;
      }
      .summary-text {
        display: inline-block;
        line-height: 1;
      }
    }
  }
}

.media-storage-info--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -.5rem;
  margin-right: -.5rem;

  .media-storage-info--chart-item {
    width: 100%;
    flex: 0 0 400px;
    padding-left: .5rem;
    padding-right: .5rem;

    .chart-details {
      display: block;
      font-weight: bold;
      font-size: 2rem;
      text-align: center;
      color: $purple2;
      margin-top: .5rem;
      margin-bottom: 1rem;

      &.small {
        margin-top: .25rem;
        font-size: 1.65rem;
      }
    }
  }
}