@import "~@ewstheme/colors/lib/scss/colors";
@import "~@ewstheme/core/lib/scss/variables";

.mainLayoutContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  min-width: 1366px;
  
  .sideBar {
    width: 250px;
    min-width: 250px;
    background-color: darken($purple1, 15%);
    height: 100%;
    transition: width 0.5s ease-in-out;
    
    &.collapsed {
      width: 60px;
    }
    
    .sideBarNavBar {
      background-color: $purple1;
      transition: all 0.5s ease-in-out;
      background-image: url('../../../../public/img/Logo-Light.png');
      background-size: auto 40px;
      background-repeat: no-repeat;
      background-position: -190px center ;
      
      .NavBarButton > * {
        color: $white;
      }
    }
    
    &:not(.collapsed) {
      .sideBarNavBar {
        background-position: 0 center;
      }
    }
  }
  
  .workspace {
    flex-grow: 1;
    height: 100%;
    
    .workspaceNavBar {
      background-color: $purple3;

      .NavBarButton > * {
        color: $white;
      }
      
      .NavBarHeading {
        color: $white;
        font-weight: 600;
        font-size: 22px;
      }
    }
  }
  
  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    
    .content {
      flex-grow: 1;
      overflow-y: auto;
      
      &:not(.allowOverflow){
        overflow-x: hidden;
      }
      
      &:not(.noPadding){
        padding: 1rem;
      }
    }
    
    .footer {
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      background-color: $purple1;
      height: 50px;
      width: 100%;
      color: $white;
      overflow: hidden;
      white-space: nowrap;
      transition: all 0.5s ease-in-out;
    }
  }
  
  .collapsed {
    &.wrapper {
      .footer {
        width: 0;
      }
    }
  }
}

.menuSearchContainer {
  width: 300px;
  padding: 0.5rem;
  max-height: 400px;
  overflow-y: auto;
  
  .menuSearchWrapper{
    display: flex;
    flex-direction: column;
    
    .menuSearchResultItem {
      cursor: pointer;
      
      &:hover {
        background-color: $purple5;
      }
    }
    
    .menuSearchResultItem:not(:last-child){
      border-bottom: 1px solid $gray5;
    }

    .menuSearchResultItem:not(:first-child) {
    }
    
    .menuSearchItemDescription {
      max-width: 100%;
      
      .wrapper{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      
    }
  }
}