@import "@ewstheme/colors/lib/scss/colors";
@import "@ewstheme/core/lib/scss/variables";

.loginBackground {
  background-color: darken($purple1, 15%);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginContainer {
  width: 100%;
  max-width: 300px;
  background: $white;
  border-radius: $pt-border-radius;
  box-shadow: $pt-dialog-box-shadow;
  
  .logo {
    width: 100%;
  }
  
  .formContainer {
    padding: .5rem;
  }
}

.tenantContainer {
  padding: .5rem;
  border-radius: $pt-border-radius;
  border: 1px solid $gray3;
  margin-bottom: .5rem;
  
  .bp4Control:last-child {
    margin-bottom: 0;
  }
}