.ews-gridcol {
  flex: 1 0 0%;
  width: 100%;
  max-width: 100%;
}

@for $i from 1 through 12 {
  .ews-gridcol-#{$i} {
    flex: 0 0 auto;
    width: percentage($i / 12);
  }

  .ews-order-#{$i} {
    order: #{$i};
  }
}