@import "~normalize.css";
@import "~@ewstheme/icons/lib/css/blueprint-icons.css";
@import "~@ewstheme/core/lib/css/blueprint.css";
@import "~@ewstheme/popover2/lib/css/blueprint-popover2.css";

.bp4-dark {
  background-color: #2f343c;
}

@import "./components/shared/utilities/text-alignment";
@import "./components/shared/busy-indicator/styles.scss";
@import "./components/shared/ews-advance-panel/styles.scss";
@import "./components/shared/ews-bg-image/styles.scss";
@import "./components/shared/ews-card/styles.scss";
@import "./components/shared/ews-dialog/styles.scss";
@import "./components/shared/ews-form/styles.scss";
@import "./components/shared/ews-grid/styles.scss";
@import "./components/shared/ews-inline-notifications/styles.scss";
@import "./components/shared/ews-loading/styles.scss";
@import "./components/shared/ews-popover/styles.scss";
@import "./components/shared/ews-sidemenu/styles.scss";
@import "./components/shared/ews-table/style.scss";
@import "./components/shared/ews-workarea/styles.scss";

.dialog-form {
  width: 800px;
  max-width: 90%;

  &.dialog-form-small {
    width: 400px;
  }

  &.dialog-form-large {
    width: 90%;
  }
  
  &.dialog-form-medium {
    width: 70%;
  }

  &.dialog-form--primary {

    .bp4-dialog-header {
      background-color: $purple3;
      color: $white;

      .bp4-heading, .bp4-icon {
        color: $white;
      }
    }
  }

  &.dialog-form--success {
    .bp4-dialog-header {
      background-color: $green3;
      color: $white;

      .bp4-heading, .bp4-icon {
        color: $white;
      }
    }
  }

  &.dialog-form--warning {
    .bp4-dialog-header {
      background-color: $orange3;
      color: $white;

      .bp4-heading, .bp4-icon {
        color: $white;
      }
    }
  }

  &.dialog-form--danger {
    .bp4-dialog-header {
      background-color: $red3;
      color: $white;

      .bp4-heading, .bp4-icon {
        color: $white;
      }
    }
  }
}

.form-action-container {
  text-align: right;
  
  .bp4-button {
    margin-left: .25rem;
  }
}

.bp4-menu {
  box-shadow: $pt-elevation-shadow-2;
}

.box-icon {
  width: 16px;
  height: 16px;
  
  &.box-icon-dialog {
    margin-left: -3px;
    margin-right: 7.5px;
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  
  &:focus {
    border-color: #2196f3;
  }
  
  &.disabled {
    opacity: 0.6;
  }
}

.wrap-code {
  word-break: initial;
  word-wrap: initial;
  white-space: break-spaces;
  word-break: break-all;
}

.clickable, .clickable * {
  cursor: pointer;
}

pre.error {
  border: 1px solid $pt-intent-danger;
  color: $pt-intent-danger;
}