﻿.ews-sidebar {
  display:flex;
  flex-direction: column;
  
  .ews-sidebar--item {
    padding: 0 20px;
    height: 50px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    color: $white;
    overflow: hidden;
    cursor: pointer;

    &.active {
      background-color: #404059;      
    }

    &:hover {
      background-color: #404059;
    }
    
    .ews-sidebar--item-text {
      white-space: nowrap;
      padding: 0 10px;
    }
  }
  
  .ews-sidebar--group {

    .ews-sidebar--group-item {
      cursor: pointer;
    }
    
    &.active {
      background-color:  #363650;

      .ews-sidebar--group-item {
        border-left: 5px solid rgba(255,255,255,0.1);
        padding: 0 20px 0 15px;
      }
    }

    .ews-sidebar--item {
      border-left: 5px solid $purple3;
      padding: 0 20px 0 15px;

      .ews-sidebar--item-text {
        white-space: nowrap;
        padding: 0 10px 0 14px;
      }
    }
    
    .ews-sidebar--group-item {
      padding: 0 20px;
      height: 50px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      color: $white;
      overflow: hidden;

      &:hover {
        background-color: #5E5E73;
      }

      .ews-sidebar--item-text {
        white-space: nowrap;
        padding: 0 10px;
      }
    }
  }
}

.ews-sidebar--hover-menu {
  background-color: darken($purple1, 10%);
  padding: 10px 0 10px 0;
  min-width: 190px;
  box-shadow: $pt-popover-box-shadow;

  .ews-sidebar--hover-menu-header {
    font-weight: 600;
    padding: 0 15px;
    color: $white;
    height: 35px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    font-size: 115%
  }

  .ews-sidebar--item {
    padding: 0 20px;
    height: 35px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    color: $white;
    overflow: hidden;
    cursor: pointer;

    &.active {
      background-color: #404059;
    }

    &:hover {
      background-color: #404059;
    }

    .ews-sidebar--item-text {
      white-space: nowrap;
      padding: 0 10px;
    }
  }
}

.bp4-dark {
  .ews-sidebar--hover-menu {
    box-shadow: $pt-dark-popover-box-shadow;
  }
}