.ews-card-container {
  padding: .5rem;
  margin-bottom: .5rem;

  &.fullheight {
    height: calc(100% - .5rem);
    display: flex;
    flex-direction: column;

    .ews-card-body {
      flex: 1 0 0%;

      .bp4-collapse-body {
        height: 100%;
      }
    }
  }
}