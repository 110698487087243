﻿.ews-text--start {
  text-align: left !important;
}
.ews-text--center {
  text-align: center !important;
}
.ews-text--end {
  text-align: end !important;
}

.ews-align--top {
  vertical-align: top;
}

.ews-align--middle {
  vertical-align: middle;
}

.ews-align--bottom {
  vertical-align: bottom;
}