.ews-gridcontainer {
  padding: .5rem;

  .ews-gridrow{
    margin: -.5rem;

    .ews-gridcol {
      padding: .5rem;
    }
  }
  .ews-grid--fluid,
  .ews-grid--fixed {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .ews-grid--fixed {
    @media (min-width: 576px) {
      max-width: 540px;
    }

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 992px) {
      max-width: 960px;
    }

    @media (min-width: 1200px) {
      max-width: 1140px;
    }

    @media (min-width: 1400px) {
      max-width: 1320px;
    }
  }

  .ews-gridrow {
    display: flex;
    flex-wrap: wrap;

    &.ews-gridrow--align-start {
      justify-content: start;
    }

    &.ews-gridrow--align-center {
      justify-content: center;
    }

    &.ews-gridrow--align-end {
      justify-content: end;
    }
  }

  &.ews-gridspacing--1 {
    padding: .15rem;

    .ews-gridrow{
      margin: -.15rem;

      .ews-gridcol {
        padding: .15rem;
      }
    }
  }

  &.ews-gridspacing--2 {
    padding: .25rem;

    .ews-gridrow{
      margin: -.25rem;

      .ews-gridcol {
        padding: .25rem;
      }
    }
  }

  &.ews-gridspacing--4 {
    padding: .75rem;

    .ews-gridrow{
      margin: -.75rem;

      .ews-gridcol {
        padding: .75rem;
      }
    }
  }

  &.ews-gridspacing--5 {
    padding: 1rem;

    .ews-gridrow{
      margin: -1rem;

      .ews-gridcol {
        padding: 1rem;
      }
    }
  }

}